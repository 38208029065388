
import { defineComponent, PropType } from 'vue'
import { Restaurant } from '@/app/api/api-types'

export default defineComponent({
  name: 'RestaurantDetails',
  props: {
    restaurant: {
      type: Object as PropType<Restaurant>,
    },
    restaurantId: String,
  },
  setup() {
    function loadedRestaurantInfo(restaurant: Restaurant) {
      return restaurant.Name !== undefined
    }
    return { loadedRestaurantInfo }
  },
})
