import appStore from '@/app/app-store'
import axios from 'axios'
import polly from 'polly-js'
import {
  Restaurant,
  RestaurantResponse,
  ConfigurationDefinition,
  UpdateRestaurantsConfigurationsRequest,
} from '@/app/api/api-types'

async function callWithRetry<T>(action: () => Promise<T>, retriesCount = 2) {
  return polly()
    .waitAndRetry(retriesCount)
    .executeForPromise(async () => {
      const response = await action()
      return response
    })
}

export async function LoadRestaurantDetails(
  id: string
): Promise<Restaurant | undefined> {
  const url = `applications/orderpad-app/restaurants/${id}/details`
  try {
    return await callWithRetry(async () => 
      {
          const response = await axios.get<RestaurantResponse>(url)
          if (response.data){
            return {
              Name: response.data.Name,
              LogoUrl: response.data.LogoUrl,
              Address: { 
                City: response.data.AddressCity,
                Postcode: response.data.PostalCode
              }
            }
          }
      })
  } catch (error) {
    console.error("Failed to fetch restaurant details after retries:", error);

    return {
      Name: undefined!,
      LogoUrl: undefined!,
      Address: undefined!
    }
  }
}

export async function LoadRestaurantConfigurations(
  id: string
): Promise<Map<string, boolean>> {
  const url = `applications/orderpad-app/restaurants/${id}/configuration`
  return await callWithRetry(async () => {
    const response = await axios.get<Map<string, boolean>>(url)
    return new Map(Object.entries(response.data))
  })
}

export async function SaveRestaurantConfigurations(
  id: string,
  configurations: Map<string, boolean>
): Promise<Map<string, boolean>> {
  const url = `applications/orderpad-app/restaurants/${id}/configuration`
  return await callWithRetry(async () => {
    const response = await axios.patch(url, Object.fromEntries(configurations))
    return new Map(Object.entries(response.data))
  })
}

export async function SaveMultipleRestaurantsConfigurations(
  payload: UpdateRestaurantsConfigurationsRequest
): Promise<any> {
  const url = `applications/orderpad-app/restaurants/configurations`
  await callWithRetry(async () => {
    await axios.post(url, payload)
  })
}

export async function LoadConfigurationDefinitions(): Promise<
  ConfigurationDefinition[]
> {
  const url = `applications/orderpad-app/restaurants/configurationdefinitions`
  return await callWithRetry(async () => {
    const response = await axios.get<ConfigurationDefinition[]>(url)
    return response.data
  })
}
